<template>
  <div>
    <div class="info mb-0 p-3 d-flex flex-column h2 shadow">
      <b-row v-if="cart.length">
        <b-col class="mb-1" cols="12">
          <div class="total__text h4 responsive-text">
            <span> Descuento: </span>
            <span> $ {{ cartDiscount | money }} </span>
          </div>
          <div class="total__text h4 responsive-text">
            <span> Subtotal: </span>
            <span> $ {{ cartSubtotal | money }} </span>
          </div>
          <div class="total__text h4 responsive-text">
            <span> IVA: </span>
            <span>
              {{ cartTax | money }}
            </span>
          </div>
          <div class="total__text h4 responsive-text">
            <span> Total: </span>
            <span>$ {{ cartTotal | money }}</span>
          </div>
        </b-col>
      </b-row>
      <div
        v-else
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <p class="pb-4 text-2xl">Tu carrito esta vacío</p>
        <Logo  />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Logo from "@core/components/Logo.vue"

export default {
  components: {
    Logo,
  },
  computed: {
    ...mapGetters("cart", [
      "cart",
      "cartTax",
      "cartTotal",
      "cartDiscount",
      "cartSubtotal",
      "CartRewardPoints",
    ]),
  },
}
</script>

<style lang="scss" scoped>
.info {
  border-radius: 10px 10px 10px 10px;
}

.dark-layout {
  .info {
    background-color: #0e1322;
  }
}

.total__text {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: #000;
}

.dark-layout {
  .total__text {
    color: #fff;
  }
}

@media (max-width: 640px) {
  .payment {
    padding: 4px !important;

    button {
      padding: 0;

      img {
        width: 50px !important;
      }

      h5 {
        font-size: 10px;
      }
    }
  }

  .responsive-text {
    font-size: 1rem !important;
  }

  .info {
    padding: 14px !important;
  }
}
</style>
