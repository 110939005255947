import { ref } from "@vue/composition-api"
import store from "@/store"

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: "",
    priceRangeDefined: "all",
    priceRange: [0, 100],
    categories: [],
    stores: [],
    brands: [],
    storeTypes: [],
    nutriScore: [],
    ratings: null,
    page: 1,
    perPage: 10,
    sku: "",
  })
  const pagination = ref({
    page: 1,
    perPage: 10,
  })

  const filterOptions = {
    priceRangeDefined: [
      { text: "All", value: "all" },
      { text: "<= $10", value: "<=10" },
      { text: "$10 - $100", value: "10-100" },
      { text: "$100 - $500", value: "100-500" },
      { text: ">= $500", value: ">=500" },
    ],
    categories: [],
    brands: [
      "Insignia™",
      "Samsung",
      "Metra",
      "HP",
      "Apple",
      "GE",
      "Sony",
      "Incipio",
      "KitchenAid",
      "Whirlpool",
    ],
    ratings: [
      { rating: 5, count: 666 },
      { rating: 4, count: 160 },
      { rating: 3, count: 176 },
      { rating: 2, count: 291 },
      { rating: 1, count: 190 },
      { rating: 0, count: 65551 },
    ],
  }

  // Sorting
  const sortBy = ref({ text: "Featured", value: "featured" })
  const sortByOptions = [
    { text: "Featured", value: "featured" },
    { text: "Lowest", value: "price-asc" },
    { text: "Highest", value: "price-desc" },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
    pagination,
  }
}

export const useShopUi = () => {
  const itemView = "list-view"
  const itemViewOptions = [
    { icon: "GridIcon", value: "grid-view" },
    { icon: "ListIcon", value: "list-view" },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useShopUiGrid = () => {
  const itemView = "grid-view"
  const itemViewOptions = [{ icon: "GridIcon", value: "grid-view" }]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const groupedProducts = ref([])
  const storeData = ref([])
  const storeAccount = ref([])
  const categories = ref([])
  const stores = ref([])
  const onlineStores = ref([])
  const packages = ref([])
  const fetchOnlineStoreProducts = (...args) =>
    store.dispatch("stores/fetchOnlineStoreProducts", ...args)
  const fetchStoreProducts = (...args) =>
    store.dispatch("stores/fetchStoreProducts", ...args)
  const fetchCategories = (...args) =>
    store.dispatch("categories/fetchCategories", ...args)
  const fetchProductPackages = (...args) =>
    store.dispatch("productPackages/fetchProductPackages", ...args)
  const fetchStores = (...args) => store.dispatch("stores/fetchStores", ...args)
  const fetchStore = (...args) => store.dispatch("stores/fetchStore", ...args)
  const fetchStoreAccount = (...args) =>
    store.dispatch("stripe/fetchStoreAccount", ...args)

  return {
    products,
    groupedProducts,
    storeData,
    storeAccount,
    fetchOnlineStoreProducts,
    fetchStoreProducts,
    categories,
    stores,
    onlineStores,
    packages,
    fetchCategories,
    fetchProductPackages,
    fetchStores,
    fetchStore,
    fetchStoreAccount,
  }
}
